import { event, googleAdConversion } from "lib/gtag";
import { track, setHeapUser } from "utils/heapanalytics";
import { fbEvent, fbCustomEvent } from "utils/facebook";
import { setKlaviyoUser, trackKlaviyo } from "utils/klaviyo";

export function sendViewContentEvent(slug) {
  fbEvent("ViewContent", { content_ids: [slug] });
}

export function sendInitiateCheckoutEvent(id) {
  fbEvent("InitiateCheckout", { content_ids: [id] });
}

export function sendPurchaseEvent(category, id, total) {
  fbEvent("Purchase", {
    currency: "USD",
    value: total,
    content_type: category,
    content_ids: [id],
  });
}

export function sendSubscribeEvent(method) {
  event("subscription", { method });
  track("User Subscription", { method });
  googleAdConversion("2vDcCJetno0CEMjinrMB");
  fbCustomEvent("UserSubscription");
}

export function sendUserSignupEvent() {
  event("sign_up", { method: "email" });
  track("User Signup", { method: "email" });
  googleAdConversion("G5UKCKzLmpECEMjinrMB");
  fbEvent("CompleteRegistration");
}

export function sendAdventureRequestEvent() {
  googleAdConversion("w4f3CKrYyfUYEMjinrMB");
}

export function sendBookingRequestEvent(tripName, total) {
  const dataProps = {
    name: tripName,
    value: total,
  };

  event("booking_request", dataProps);
  track("Booking Request", dataProps);
  googleAdConversion("g94rCJnF5KQCEMjinrMB");
  fbCustomEvent("BookingRequest");
  trackKlaviyo("Booking request", dataProps);
}

export function sendMessageEvent(source) {
  event("message_sent", { source });
  track("Message Sent", { source });
  googleAdConversion("P09wCMSr5KQCEMjinrMB");
  fbEvent("Contact");
  trackKlaviyo("Message sent", { source });
}

export function sendPaymentEvent(transactionNumber, total) {
  event("purchase", {
    currency: "USD",
    transaction_id: transactionNumber,
    value: total,
  });
  track("Purchase", {
    currency: "USD",
    transaction_id: transactionNumber,
    value: total,
  });
  googleAdConversion("GVrzCJ619ZACEMjinrMB", {
    currency: "USD",
    value: total,
  });
  fbEvent("Purchase", {
    currency: "USD",
    value: total,
  });
  trackKlaviyo("Purchase", {
    currency: "USD",
    transaction_id: transactionNumber,
    value: total,
  });
}

export function setUser(response) {
  setHeapUser(response);
  setKlaviyoUser(response);
}

export function trackTripView({ id, name }) {
  const dataProps = {
    tripID: id,
    tripName: name,
  };

  trackKlaviyo("Trip page viewed", dataProps);
}

export function trackSaveTrip(tripId) {
  trackKlaviyo("Saved trip", { tripId });
}

export function trackCreateWishlist(wishlistName) {
  track("Create wishlist", {
    wishlistName,
  });
  trackKlaviyo("Create wishlist", { wishlistName });
}

export function trackHomeAdventureLeadSubmission() {
  fbEvent("Lead");
}

export function trackLandingPageAdventureLeadSubmission() {
  fbEvent("Lead");
}
